"use client";

import { useEffect, useState } from "react";
import Script from "next/script";
import safeDataLayerPush from "lib/safeDataLayerPush";
import { usePathname } from 'next/navigation';
const containerId = "GTM-KBJJBHH";
const noScriptURL = `https://www.googletagmanager.com/ns.html?id=${containerId}`;
const snippet = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${containerId}');`;

//This "has" to get sent before all other events,
//or else Scandiweb says none of the other events will get "routed" to the "right channels":
const pushGeneralEvent = relativePath => {
  if (!relativePath) {
    return;
  }
  const firstPathSegment = relativePath.split("/").filter(segment => segment.length)[0];
  // https://docs.google.com/spreadsheets/d/1c4Jtp357yYMZ6-PHhpapPKW7LMrqYDtvrrFV7aulKXA/edit#gid=2125107399
  safeDataLayerPush({
    event: "general",
    current_environment: "metmuseum.org",
    content_group: firstPathSegment || "homepage" // obviously "/".split("/")[0] is undefined
  });
};
const GTM = () => {
  const pathname = usePathname();
  const [showTags, setShowTags] = useState(false);
  useEffect(() => {
    // toggle this clientside in useEffect to prevent hydration mismatch errors with GTM
    setShowTags(true);
  }, []);
  useEffect(() => {
    const url = pathname;
    pushGeneralEvent(url);
  }, [pathname]);

  //TODO refactor to handle fragment/conditional logic //https://github.com/getsentry/sentry-javascript/issues/2342#issuecomment-1689174100
  return <>
		{showTags && <noscript><iframe src={noScriptURL} height="0" width="0" style={{
        display: "none",
        visibility: "hidden"
      }}></iframe></noscript>}
		{showTags && <Script id="google-tag-manager" strategy="afterInteractive">{snippet}</Script>}
	</>;
};
export default GTM;